import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Card, LoadingSpinner, MoreActionsButton, TextLink } from "components";
import { LinkIcon } from "components/icons";
import EditSiteDrawer from "./edit-site-drawer";
import { selectCompany } from "state/app-slice";

import styles from "./site-card.module.scss";

const address = [
    "address_line_1",
    "address_line_2",
    "address_line_3",
    "address_post_town",
    "address_post_code",
];

const renderAddress = (site) => {
    const addressLines = address.map((line) => {
        if (!site[line]) {
            return null;
        }

        return (<p key={line} className={styles.addressLine}>{site[line]}</p>);
    });

    return addressLines.filter((line) => line).length ? addressLines : "No address";
};

/**
 * More detailed site card including display of schemes
 *
 * @param {object} props The site card properties
 * @param {object} props.site The company site
 * @param {array} props.schemes The schemes for a site
 * @param {boolean} props.schemesLoading The loading status
 *
 * @returns {React.Component} Renders the site card
 */
const SiteCard = ({ site, schemes, schemesLoading }) => {
    const { id } = useParams();
    const { app } = useSelector((state) => state);
    const { stats: { employee_count: employeeCount } } = site;
    const [openEditDrawer, setOpenEditDrawer] = useState(false);

    /**
     * Finds current company and gets relevant permissions
     *
     * @returns {object} selected company and permissions
     */
    const { companyPermissions: { sites: sitesPermissions } } = useMemo(() => selectCompany(app, id), [app, id]);

    /**
     * Shows the linked schemes in named badges
     *
     * @returns {React.Component} Renders the linked scheme badges else the empty scheme
     */
    const renderLinkedSchemes = () => {
        if (isEmpty(schemes)) {
            return (
                <div>
                    <div>No linked Scheme</div>
                </div>
            );
        }

        return schemes.map((scheme) => {
            return (
                <TextLink key={scheme.id} className={styles.schemeBadge} to={`/companies/${id}/schemes/${scheme.id}`}>
                    <div><LinkIcon /></div>
                    <div className={styles.schemeText}>{scheme.name}</div>
                </TextLink>
            );
        });
    };

    return (
        <>
            <Card className={styles.cardWrapper} padding={false}>
                <div className={styles.moreActionsAlign}>
                    <MoreActionsButton
                        actions={[
                            {
                                label: "Edit",
                                handler: () => setOpenEditDrawer(true),
                                hasPermission: sitesPermissions.canEdit,
                            },
                        ]}
                    />
                </div>
                <div className={styles.companyInfo}>
                    <div>
                        <div className={styles.subtitle}>Name</div>
                        <div className={styles.title}>{site.name}</div>
                    </div>
                    <div>
                        <div className={styles.subtitle}>Address</div>
                        <div className={styles.address}>{renderAddress(site)}</div>
                    </div>
                    <div>
                        <div className={styles.subtitle}>Employees</div>
                        <div className={styles.employeeCount}>{employeeCount}</div>
                    </div>
                </div>
                <div className={classNames(styles.subtitle, styles.schemesSubtitle)}>Schemes</div>
                <div className={styles.schemes}>
                    <div className={styles.linkedSchemes}>
                        {schemesLoading ? <LoadingSpinner /> : renderLinkedSchemes()}
                    </div>
                </div>
            </Card>
            <EditSiteDrawer
                isOpen={openEditDrawer}
                setIsOpen={setOpenEditDrawer}
                isLoading={schemesLoading}
                onCancel={() => setOpenEditDrawer(false)}
                site={site}
                companyId={id}
            />
        </>
    );
};

SiteCard.propTypes = {
    site: PropTypes.object.isRequired,
    schemes: PropTypes.arrayOf(PropTypes.object).isRequired,
    schemesLoading: PropTypes.bool.isRequired,
};

export default SiteCard;
